<template>
  <div id="container">
    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">5</div>
            <p>カード情報の<br>登録へ進む</p>
          </div>
        </div>

        <form @submit="nextClicked">

          <div class="cts_box">
            <h2 class="sub_ttl"><span>塾生情報の登録</span></h2>
            <p class="reg_lead">
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
            </p>

            <form-error-box v-if="errors"/>

            <div class="input_set name">
              <div class="label">おなまえ<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <div class="label">姓</div>
                  <input type="text" maxlength="16" v-model="new_user.last_name"
                         v-bind:class="{error: validation_errors.last_name}"
                         @input="new_user.last_name = replaceHalfToFull(new_user.last_name)"
                         placeholder="例：山田">
                  <validation-errors :errors="validation_errors.last_name"
                                     v-if="validation_errors.last_name"></validation-errors>
                </div>
                <div class="field">
                  <div class="label">名</div>
                  <input type="text" maxlength="16" v-model="new_user.first_name"
                         v-bind:class="{error: validation_errors.first_name}"
                         @input="new_user.first_name = replaceHalfToFull(new_user.first_name)"
                         placeholder="例：太郎">
                  <validation-errors :errors="validation_errors.first_name"
                                     v-if="validation_errors.first_name"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set kana">
              <div class="label">フリガナ<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <div class="label">姓（全角カナ）</div>
                  <input type="text" maxlength="16" v-model="new_user.last_name_kana"
                         v-bind:class="{error: validation_errors.last_name_kana}"
                         @input="new_user.last_name_kana = replaceHiraToKana(new_user.last_name_kana)"
                         placeholder="例：ヤマダ">
                  <validation-errors :errors="validation_errors.last_name_kana"
                                     v-if="validation_errors.last_name_kana"></validation-errors>
                </div>
                <div class="field">
                  <div class="label">名（全角カナ）</div>
                  <input type="text" maxlength="16" v-model="new_user.first_name_kana"
                         v-bind:class="{error: validation_errors.first_name_kana}"
                         @input="new_user.first_name_kana = replaceHiraToKana(new_user.first_name_kana)"
                         placeholder="例：タロウ">
                  <validation-errors :errors="validation_errors.first_name_kana"
                                     v-if="validation_errors.first_name_kana"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set">
              <div class="label">性別<span>*</span></div>
              <div class="radio" v-bind:class="{error: validation_errors.gender}">
                <input id="radio01" type="radio" v-model="new_user.gender" value="male">
                <label for="radio01">男性</label>

                <input id="radio02" type="radio" v-model="new_user.gender" value="female">
                <label for="radio02">女性</label>

                <input id="radio03" type="radio" v-model="new_user.gender" value="secret">
                <label for="radio03">その他</label>
              </div>
              <validation-errors :errors="validation_errors.gender" v-if="validation_errors.gender"></validation-errors>
            </div>

            <div class="input_set birth">
              <div class="label">生年月日<span>*</span></div>
              <p>すべての欄に半角数字でご入力下さい。</p>
              <div class="input_fields flex">
                <div class="field">
                  <input type="text" v-model="new_user.birthday_year"
                         @input="new_user.birthday_year = replaceFullToHalf(new_user.birthday_year)"
                         v-bind:class="{error: validation_errors.birthday_year}" placeholder="年">
                  <validation-errors :errors="validation_errors.birthday_year"
                                     v-if="validation_errors.birthday_year"></validation-errors>
                </div>
                <div class="field">
                  <input type="text" v-model="new_user.birthday_month"
                         @input="new_user.birthday_month = replaceFullToHalf(new_user.birthday_month)"
                         v-bind:class="{error: validation_errors.birthday_month}" placeholder="月">
                  <validation-errors :errors="validation_errors.birthday_month"
                                     v-if="validation_errors.birthday_month"></validation-errors>
                </div>
                <div class="field">
                  <input type="text" v-model="new_user.birthday_day"
                         @input="new_user.birthday_day = replaceFullToHalf(new_user.birthday_day)"
                         v-bind:class="{error: validation_errors.birthday_day}" placeholder="日">
                  <validation-errors :errors="validation_errors.birthday_day"
                                     v-if="validation_errors.birthday_day"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set email">
              <div class="label">ユーザーネーム（表示名）<span>*</span></div>
              <p>複眼経済塾WEBサイト上で他の塾生に表示されるお名前です。ログイン後に「マイページ」で変更できます。</p>
              <input type="text" class="nickname" v-bind:class="{error: validation_errors.humhub_username}"
                     v-model="new_user.humhub_username">
              <ValidationErrors :errors="validation_errors.humhub_username"
                                v-if="validation_errors.humhub_username"></ValidationErrors>
            </div>

            <div class="input_set email">
              <div class="label">連絡先メールアドレス<span>*</span></div>
              <input type="text" class="email" v-bind:class="{error: validation_errors.email}"
                     v-model="new_user.email">
              <ValidationErrors :errors="validation_errors.email"
                                v-if="validation_errors.email"></ValidationErrors>
            </div>

            <div class="input_set email">
              <div class="label">連絡先メールアドレス(確認)<span>*</span></div>
              <input type="text" class="email" v-bind:class="{error: validation_errors.email_confirmation}"
                     v-model="new_user.email_confirmation">
              <ValidationErrors :errors="validation_errors.email_confirmation"
                                v-if="validation_errors.email_confirmation"></ValidationErrors>
            </div>

            <div class="input_set phone">
              <div class="label">連絡先電話番号<span>*</span></div>
              <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
              <input type="tel" v-model="new_user.phone_number" v-bind:class="{error: validation_errors.phone_number}"
                     @input="new_user.phone_number = replaceFullToHalf(new_user.phone_number)"
                     placeholder="例：09012345678">
              <validation-errors :errors="validation_errors.phone_number"
                                 v-if="validation_errors.phone_number"></validation-errors>
            </div>

            <div class="input_set email" v-if="showShikihoAccount()">
              <div class="label">東洋経済新報社の「会社四季報ONLINE（有償版）」にすでに加入している方はチェックを入れてください。</div>
              <div class="checkbox">
                <input id="sns_notice02" type="checkbox" v-model="show_shikiho_account"
                       @change="changeShikihoAccount()">
                <label for="sns_notice02" class="flex">
                  会社四季報ONLINE（有償版）加入済み
                </label>
              </div>
              <template v-if="show_shikiho_account">
                <p class="mt5">
                  <span class="bold">年間契約の残存期間がある方は返金を行います。下記の欄に会社四季報ONLINEのログイン用ID（メールアドレス）を入力してください。</span><br>
                  後日、返金のための銀行口座を事務局よりおうかがいします。<br>
                  「会社四季報ONLINE（<span class="red bold">ゲスト・無料会員</span>）」の方は<span class="red bold">返金がないため対象外</span>です。<br>
                  <span class="red bold">「会社四季報ONLINE」のIDをお持ちでない方は何も入力しないでください。</span>IDの確認方法は<a href="https://www.millioneyes.jp/shikiho_online_id/" target="_blank"><span class="bold">こちら。</span></a>
                </p>
                <div class="label" style="vertical-align: middle">
                  <img src="/common/images/shikiho_logo.svg" style="width: 100px; vertical-align: middle" alt="">
                  会社四季報ONLINEログイン用ID（メールアドレス）
                </div>
                <input type="text" class="email" v-model="new_user.shikiho_account"
                       v-bind:class="{error: validation_errors.shikiho_account}"
                       @input="new_user.shikiho_account = replaceFullToHalf(new_user.shikiho_account)"
                       placeholder="">
                <div class="shikiho_account_guide">
                  （１）すでにご利用いただいている「会社四季報ONLINE」の年間契約が残っている場合は塾生割引と重複する期間分をご返金いたします。<br>
                  （２）すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）を塾生割引のログイン用メールアドレス に&nbsp;<span class="red bold">紐付け</span>&nbsp;を行います。（紐付けを行うことにより各種の設定や銘柄等の設定を引き継ぐことができます）<br>
                  （３） 紐付けが完了すると、すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）でログインできるようになります。（紐付けが完了するまではすでにご利用いただいている ログイン用メールアドレス 、塾生割引ログイン用メールアドレス ともにお使いいただけます）<br>
                </div>
              </template>
            </div>

            <p class="alnC sp_alnL mb20">
              すべての項目に入力したらこちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="住所の登録">
            </div>
          </div>

        </form>

      </div><!--//.wrap-->
    </section>
  </div><!--//＃container-->
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'
import FormErrorBox from '@/elements/FormErrorBox.vue'

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    FormErrorBox
  },
  data() {
    return {
      new_user: this.$store.state.user && this.$store.state.user.init ? this.$store.state.user : {
        init: true,
        id: null,
        last_name: null,
        first_name: null,
        last_name_kana: '',
        first_name_kana: null,
        humhub_username: null,
        birthday_year: null,
        birthday_month: null,
        birthday_day: null,
        email: null,
        email_confirmation: null,
        phone_number: null,
        shikiho_account: null,
        gender: null,
        male: null,
        zip: null,
        prefecture: null,
        address1: null,
        address2: null,
        address3: null,
        opt_zip: null,
        opt_address1: null,
        opt_address2: null,
        opt_address3: null,
        enable_primary_address: null,
        password: null,
        password_confirmation: null,
        questionnaire_ids: null,
      },
      show_shikiho_account: false,
      validation_errors: {},
      errors: null,
    }
  },

  mounted() {
    if (!this.$store.state.user) {
      this.$router.push({
        name: 'UsersRegistration',
        params: this.$route.params
      })
      return
    }
    //console.log(this.$store.state.user)
    Object.assign(this.new_user, this.$store.state.user)
    this.$store.state.user = this.new_user
  },

  methods: {
    changeShikihoAccount() {
      if (!this.show_shikiho_account) {
        this.new_user.shikiho_account = null
      }
    },
    showShikihoAccount() {
      const shikiho = this.$store.state.user.shikiho
      const rank = this.$store.state.user.rank
      // console.log(rank, shikiho)
      return rank === 'rank_regular_plus_year' ||
          rank === 'rank_regular_plus_month' ||
          shikiho === 'rank_regular_shikiho_premium_year' ||
          shikiho === 'rank_regular_shikiho_basic_year'
    },
    nextClicked(event) {
      const keys = [
        'first_name', 'last_name', 'first_name_kana', 'last_name_kana', 'humhub_username', 'gender',
        'birthday_year', 'birthday_month', 'birthday_day', 'phone_number', 'email', 'email_confirmation'
      ]

      event.preventDefault()
      this.validation_errors = {}
      this.errors = null
      this.$store.state.user = this.new_user

      this.axios
          .post(`${this.env.api_base_url}users/validate.json`, {
            user: this.new_user,
          })
          .then(() => {
            this.$router.push({
              name: 'UsersConfirmationPage2',
              params: this.$route.params
            })
          })
          .catch((error) => {
            this.scrollTop()
            if (error.response.data.errors) {
              keys.forEach(key => {
                if (error.response.data.validation_errors[key]) {
                  this.validation_errors[key] = error.response.data.validation_errors[key]
                }
              })
              if (Object.keys(this.validation_errors).length === 0) {
                this.$router.push({
                  name: 'UsersConfirmationPage2',
                  params: this.$route.params
                })
              } else {
                this.errors = true
              }
            }
            if (error.response.data && error.response.data.error) {
              // TODO: エラー処理
              // this.errors = [error.response.data.error]
            }
            this.$forceUpdate();
          });
    }
  }
}
</script>
